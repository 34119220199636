@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

:root {
  --main-font: "Open Sans", sans-serif;
  --main-color: #252c37;
  --main-margin: 40px auto;
}

* {
  letter-spacing: -0.005em;
  font-weight: 400;
  font-family: var(--main-font);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-style: normal;
  line-height: 1.05;
  font-weight: 600;
}

h1 {
  color: var(--main-color);
  font-size: 80px;
  margin: var(--main-margin);
}

h2 {
  color: var(--main-color);
  font-size: 56px;
  margin: var(--main-margin);
}

h3 {
  font-size: 32px;
  margin: 24px 0;
}

h4 {
  font-size: 24px;
  margin: 20px 0;
}

h5 {
  font-size: 20px;
  margin: 16px 0;
}

h6 {
  font-size: 16px;
  margin: 12px 0;
}

.App-container {
  max-width: 87.5vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-title {
  margin-top: 80px;
  margin-bottom: 0;
}

.App-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
  color: #666;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
}

.About-section {
  background-color: #f5f5f7;
  padding: 20px;
  @media (min-width: 900px) {
    padding: 120px;
  }
}

.experience-icon-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 24px 0;
  gap: 20px;
}

.experience-icon-box {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 20px;
  border-radius: 24px;
  box-shadow: 4px 8px 10px rgba(0, 0, 0, 0.2);
}

.experience-icon {
  height: 100px;
}
