.resume-container {
  display: grid;
  padding: 24px 0;
  grid-template-rows: 16px 16px 5px;
  width: 100%;
  flex: 1;
}

.timeline-modal-role,
.timeline-event-container {
  display: flex;
}

.timeline-modal-title,
.timeline-modal-role-title,
.timeline-modal-role-subtitle {
  font-size: 16px;
  margin: 0;
  padding-bottom: 8px;
}

.timeline-modal-date,
.timeline-modal-role-date {
  font-style: italic;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  padding-bottom: 8px;
}

.timeline-modal-role-details {
  margin: 0;
  padding-bottom: 4px;
}

.timeline-event-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 48px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.timeline-event {
  margin-top: 24px;
  height: 100px;
  width: 300px;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #000138;
  background-color: #000138;
  background: linear-gradient(#000138, #000018);
  padding: 0 12px;
  text-align: center;
  color: white;
}
