.image {
  margin: auto;
  height: 200px;
  width: 200px;
  border-radius: 48px;
  object-fit: cover;
  border: 2px solid grey;
}

.image-div {
  height: 200px;
  width: 200px;
  margin: auto;
  border-radius: 24px;
}
