#activity-grid {
  display: grid;
  max-width: 220px;
  grid-template-columns: repeat(13, 15px); /* 7 days in a week */
  gap: 2px;
  background-color: white;
  border-radius: 10px;
  padding: 12px;
  margin: 0 auto;
  @media (min-width: 900px) {
    padding: 24px;
    border-radius: 18px;
    max-width: 350px;
    grid-template-columns: repeat(13, 25px); /* 7 days in a week */
  }
}

.day {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  @media (min-width: 900px) {
    border-radius: 4px;
    width: 25px;
    height: 25px;
  }
}

.graph-container {
  text-decoration: none;
  color: black;
  height: 166px;
}

.integration-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
}

.day-0 {
  background-color: #ebedf0;
} /* No commits */
.day-1 {
  background-color: #9be9a8;
} /* 1 commit */
.day-2 {
  background-color: #40c463;
} /* 2-4 commits */
.day-3 {
  background-color: #30a14e;
} /* 5-7 commits */
.day-4 {
  background-color: #216e39;
} /* 8+ commits */

.github-integration-logo {
  height: 12px;
}
