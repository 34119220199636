.About-heading {
  margin-top: 0;
  text-align: center;
}

.about-description {
  color: #6e6e73;
  line-height: 25px;
}

.about-image {
  max-width: 100%;
  height: auto;
  border-radius: 18px;
}

.about-image-shadow {
  box-shadow: 5px 5px 10px 0px rgba(83, 83, 83, 0.5);
}

.about-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.about-image-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
}

.about-section {
  background-color: white;
  padding: 40px;
  border-radius: 18px;
  display: grid;
  gap: 80px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
}
