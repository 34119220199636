.social-image,
.social-container {
  cursor: pointer;
  display: flex;
}

.social-image {
  height: auto;
  max-height: 50px;
  width: 50px;
}

#social-links-container,
.social {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 680px) {
  #social-links-container {
    text-align: center;
  }
}

.social-container {
  justify-content: space-around;
  padding: 24px 0;
  gap: 32px;
}

.aboutSection {
  background-color: #f5f5f7;
}
