.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 375px;
  min-width: 375px;
  height: 640px;
  border-radius: 24px;
  box-shadow: 4px 8px 10px rgba(0, 0, 0, 0.2);
}

.project:hover {
  transform: scale(1.05);
  transition: transform 0.5s ease;
}

.projectsContainer {
  padding-left: 6.25vw;
}

.projectContainer {
  padding: 32px 6.25vw;
  margin-left: -6.25vw;
  overflow-x: scroll;
  display: flex;
  grid-gap: 20px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.projectContainer::-webkit-scrollbar {
  display: none;
}

.projectBackgroundImage {
  width: 300px;
  border-radius: 30px;
}
