.modal {
  z-index: 100;
  position: fixed;
  top: calc(50vh - 25%);
  max-height: calc(100vh - 20px);
  padding: 76px;
  left: calc(5vw - 76px);
  width: 90vw;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 18px;
}

.modal__content {
  height: 100%;
}

@media (min-width: 768px) {
  .modal {
    left: calc(25vw - 76px);
    width: 50vw;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}
